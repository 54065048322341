import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Col, Row, Navbar, Nav, NavItem} from 'react-bootstrap';

export default class AppNavigator extends Component {
  render() {
    return (
			<React.Fragment>
			<Row>
				<Navbar collapseOnSelect>

					<Navbar.Header>
						<Navbar.Brand>
							<Link to="/" style={{color:'#fff'}}>
								Paragon Pets
							</Link>
						</Navbar.Brand>
						<Navbar.Toggle/>
					</Navbar.Header>

					<Navbar.Collapse>
						<Nav pullRight>
							<NavItem eventKey={1} href="/">Home</NavItem>
              <NavItem eventKey={3} href="/category/Dogs">Dogs</NavItem>
							<NavItem eventKey={2} href="/category/Cats">Cats</NavItem>
							<NavItem eventKey={5} href="/category/Small Animals">Small Animals</NavItem>
							<NavItem eventKey={6} href="/category/Others">Others</NavItem>
							<NavItem eventKey={6} href="/member">Members</NavItem>
							<NavItem eventKey={7} href="/page/about-us">About Us</NavItem>
							<NavItem eventKey={7} href="/page/services">Our Services</NavItem>
							<NavItem eventKey={7} href="/page/outlets">Our Outlets</NavItem>
							<NavItem eventKey={8} href="/page/contacts">Contact Us</NavItem>
						</Nav>
					</Navbar.Collapse>

				</Navbar>
			</Row>
			</React.Fragment>
    );
  }
}
/*
<Row>
  <Col xsHidden md={12} style={{paddingTop:10}}></Col>
</Row>
*/
