import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Grid, Col, Row, Navbar, Nav, NavItem} from 'react-bootstrap';

export default class AppFooter extends Component {
  render() {
    return (
			<React.Fragment>
			<Row className="app-footer">
				<Col xs={12} md={3}>
					<strong>Categories</strong><hr className="spliter"/>
          <NavItem eventKey={3} href="/category/Dogs">Dogs</NavItem>
					<NavItem eventKey={2} href="/category/Cats">Cats</NavItem>
          <NavItem eventKey={5} href="/category/Small Animals">Small Animals</NavItem>
					<NavItem eventKey={6} href="/category/Others">Others</NavItem>
					<br/>
				</Col>
				<Col xs={12} md={3}>
					<strong>Navigate</strong><hr className="spliter"/>
					<NavItem eventKey={7} href="/page/about-us">About Us</NavItem>
					<NavItem eventKey={7} href="/page/services">Our Services</NavItem>
					<NavItem eventKey={7} href="/page/outlets">Our Outlets</NavItem>
					<NavItem eventKey={8} href="/page/contacts">Contact Us</NavItem>
					<NavItem eventKey={6} href="/member">Members</NavItem>
					<br/>
				</Col>
				<Col xs={12} md={3}>
					<strong>Download Our App</strong><hr className="spliter"/>
					See your transaction histories, current points and more.<br/>
          <div className="footer-app-download">
  					<a href="https://play.google.com/store/apps/details?id=com.paragonpets">
  					   <img style={{width:120}} src="/images/badge-android.png" alt="Android - Paragon Pets Mobile App"/>
  					</a>
            <a href="https://itunes.apple.com/my/app/paragon-pets/id1443577787?mt=8">
  			       <img style={{width:120}} src="/images/badge-ios.png" alt="iOS - Paragon Pets Mobile App"/>
            </a>
          </div>
					<br/>
					<br/>
				</Col>
        <Col xs={12} md={3}>
          <strong>Follow Us</strong><hr className="spliter"/>
          <a href="https://www.facebook.com/ppsbsabah/">
             <img className="img-responsive" src="/images/fb-findus.png" alt="Paragon Pets Facebook"/>
          </a><br/>
          <a href="https://www.facebook.com/ppsbsabah/"><u>Visit Paragon Pets Facebook Page</u></a>
        </Col>
			</Row>
			<Row>
				<div align="right">
				<br/>
				</div>
			</Row>
			</React.Fragment>
    );
  }
}
