import React, { Component } from 'react';
import {Alert, Button, Grid, Row, Col, Clearfix, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import { Slide, Fade } from 'react-slideshow-image';

import {AppActivity} from '../component/apputils.js';
import Navigator from '../Navigator.js';
import Footer from '../Footer.js';
import {appAPI} from '../component/appconst.js';
import '../App.css';

export default class Page extends Component {
  constructor(props) {
    super(props)
    this.state = {
			appFlow: 'loading',
			appMethod: '',
      body:[],
      slideshow:[],
      name:'',
      sender_name:'',
      sender_email:'',
      sender_mobile: '',
      sender_subject:'',
      sender_message:'',
      sent: false
    }
  }

	componentDidMount() {
		let name = this.props.match.params.page;
    this.setState({
      name:name
    },function(){
      this.getPage(name);
    })
	}

	getSlideshowName() {
		switch(this.props.match.params.page) {
			case 'about-us': return 'About Us';
			case 'outlets': return 'Outlets';
			case 'services': return 'Our Services';
			case 'contacts': return 'Contact Us';
			default: return null;
		}
	}

	getPage(name) {
		let api = appAPI + 'method=page&name=' + name;
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			this.setState({
				body:resj.data[0].body[0],
				slideshow:resj.data[0].slideshow
			},function(){
        this.setState({
          appFlow:'default'
        })
      })
		})
		.catch((error) => {
			console.log('')
			this.setState({
				appFlow:'error',
				appMethod:'::getPage'
			})
		})
	}

	renderSlideshowImage() {
		const {slideshow} = this.state;
		return slideshow.map((item, index) => {
			return (
				<div key={index} className="app-slideshow">
					<div style={{
						'backgroundImage':`url(${item.uri})`
					}}>
					</div>
				</div>
			)
		})
	}

	renderSlideshow() {
		const slideshow = this.state.slideshow;
		if(slideshow.length > 0) {
			if(slideshow.length === 1) {
				return (
					<div style={{marginBottom:20}}>
						<img
							src={slideshow[0].uri}
							alt={slideshow[0].heading}
							className="img-responsive"
						/>
					</div>
				);
			}
			else {
				const settings = {
					duration: 5000,
					transitionDuration: 500,
		  		infinite: true,
		  		indicators: true,
		  		arrows: false
				}
				return (
					<Slide {...settings}>
						{this.renderSlideshowImage()}
					</Slide>
				);
			}
		}
	}

  handleFormInputChange = (event) => {
    const{ target:{name, value} } = event
    this.setState({
      [name]: value
    })
  }

  checkInquiry() {
    const {
      sender_name,
      sender_email,
      sender_message
    } = this.state;

    if(sender_name === ''
    || sender_email === ''
    || sender_message === '') {
      alert('We need at least, your name, email and message, for us to respond to your properly.');
    }
    else {
      this.sendInquiry()
    }
  }

  sendInquiry() {
    console.log('sending inquiry...');
    this.setState({
      appFlow:'sending',
    })
    const {
      sender_name,
      sender_email,
      sender_mobile,
      sender_subject,
      sender_message
    } = this.state;

    let api = appAPI + 'method=inquiry'
    return fetch(api, {
      method:'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        name:sender_name,
        email:sender_email,
        mobile:sender_mobile,
        subject:sender_subject,
        message:sender_message
      })
    })
    .then((resp) => resp.json())
    .then((resj) => {
      console.log('api response: ')
      console.log(resj.data)
      this.setState({
        appFlow:'default',
        sent:true,
        sender_name:'',
        sender_email:'',
        sender_mobile:'',
        sender_subject:'',
        sender_message:''
      })
    })
    .catch((err) => {
      console.log(err)
      this.setState({
        appFlow:'error',
        appMethod:'::sendInquiry'
      })
    })
  }

  renderInquiryForm() {
    if(this.state.name === 'contacts') {
      return (
        <React.Fragment>
        <Col xs={12} md={12}>
          <Row>
            <hr className="spliter"/>
            <Col xs={12} md={12}>
              <h4>Contact Us Via Our Online Inquiry Form</h4><br/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
            <form>
              <FormGroup>
                <ControlLabel>Your Name</ControlLabel>
                <FormControl
                  name="sender_name"
                  value={this.state.sender_name}
                  onChange={this.handleFormInputChange}
                  type="text"
                  placeholder="Your full name"
                />
                <br/>
                <ControlLabel>Your Mobile NO.</ControlLabel>
                <FormControl
                  name="sender_mobile"
                  value={this.state.sender_mobile}
                  onChange={this.handleFormInputChange}
                  type="text"
                  placeholder="Kindly provide a correct mobile no so that we can contact you if neccesary."
                />
                <br/>
                <ControlLabel>Your Email</ControlLabel>
                <FormControl
                  name="sender_email"
                  value={this.state.sender_email}
                  onChange={this.handleFormInputChange}
                  type="text"
                  placeholder="Kindly provide a correct email so that we can get back to you."
                />
                <br/>
                <ControlLabel>Subject</ControlLabel>
                <FormControl
                  name="sender_subject"
                  value={this.state.sender_subject}
                  onChange={this.handleFormInputChange}
                  type="text"
                  placeholder="subject of your inquiry"
                />
                <br/>
                <ControlLabel>Your Message</ControlLabel>
                <FormControl
                  name="sender_message"
                  value={this.state.sender_message}
                  onChange={this.handleFormInputChange}
                  componentClass="textarea"
                  rows={8}
                  placeholder="Your messages"
                />
                <br/>
                <Button
                  type="button"
                  onClick={() => this.checkInquiry()}
                  className="btn btn-primary"
                >
                  Send My Inquiry Now
                </Button>
                <FormControl.Static>
                  Kindly allow three(3) to seven(7) days for us to reply your inquiry.
                </FormControl.Static>
              </FormGroup>
            </form>
            </Col>
          </Row>
        </Col>
        </React.Fragment>
      );
    }
  }

  renderAlert() {
    if(this.state.sent === true) {
      return (
          <Col xs={12} md={12}>
            <Alert bsStyle="info">
              <strong>Inquiry Sent!</strong> Thank you, for sending
              in your inquiry. We will get back to you as soon as possible.
            </Alert>
          </Col>
      );
    }
  }

  //----------------------------------------------------------------------------

	htmlMarkup(output) {
		if(output !== undefined) {
			return {__html: output.replace(/\n/g, '<br />')};
		}
	}

	//----------------------------------------------------------------------------

  render() {
    let body = this.state.body;
		switch(this.state.appFlow) {
			case 'default':
				return (
					<div className="app-canvas">
					<Grid fluid>
						<Navigator/>
          </Grid>
          <Grid>
						<Row className="app-body-container">
              {this.renderAlert()}
							<Col xs={12} md={12}>
								<h2>{body.title}</h2>
								<br/>
								{this.renderSlideshow()}
								<div dangerouslySetInnerHTML={this.htmlMarkup(body.main_section)}></div>
								<br/>
							</Col>
              {this.renderInquiryForm()}
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

      case 'sending':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity
                  text="Sending your inquiry..."
                />
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								Unable to display content. Please check your internet connection status
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

		}
  }
}
