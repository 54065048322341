import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {AppActivity} from '../component/apputils.js';
import Navigator from '../Navigator.js';
import Footer from '../Footer.js';
import {Grid, Row, Col, Clearfix} from 'react-bootstrap';
import {sampleProducts} from '../component/products.js';
import {appURL, appAPI} from '../component/appconst.js';
import '../App.css';

export default class SubCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
			appFlow: 'loading',
			appMethod: '',
      name: null,
      sub:null,
			products: [],
    }
  }

	componentDidMount() {
		let name = this.props.match.params.name;
    let sub = this.props.match.params.sub;
		this.setState({
			name: name,
      sub:sub
		},function(){
			this.fetchProducts()
		})
	}

	fetchProducts() {
		let api = appAPI + 'method=subitems'
            + '&name='+this.state.name
            + '&sub='+this.state.sub

		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			if(resj.success === true) {
				this.setState({
					products: resj.data,
				},function(){
					console.log('product fetched');
					console.log(this.state.products);
          this.fetchSubCatgory()
				})
			}
			else {
				this.setState({
					appFlow:'default'
				})
			}
		})
		.catch((error) => {
			this.setState({
				appFlow:'error',
				appMethod:'::fetchProducts'
			})
			console.log(error);
		})
	}

	renderProductImage(image, name) {
		if(image === null) {
			return (
				<img src="../../images/empty_100x100.png" alt={name} className="app-item"/>
			);
		}
		else {
			// return image with api path
			return (
				<img src={appURL + image} alt={name} className="app-item"/>
			);
		}
	}

  renderProductPrice(enable, price, subcategory) {
		if(enable === 1) {
			let rate = Number(price).toFixed(2)
			return (
				<p>
					RM {rate}<br/>
					<small>Sub Category: {subcategory}</small>
				</p>
			);
		}
		else {
			return (
				<p>
					RM <span style={{color:'grey'}}>N/A</span><br/>
					<small>Sub Category: {subcategory}</small>
				</p>
			);
		}
	}

	renderProducts() {
		let products = this.state.products;
		let data = [];
		for(let i=0;i<products.length;i++) {
			data.push(products[i])
		}
		if(data.length > 0) {
			return data.map((item, index) => {
				let name 	= item.item_name;
				let code 	= item.name;
				let rate 	= item.standard_rate;
				let image = item.image;

				return (
					<Col xs={6} md={3} className="app-item-col" key={index}>
						<Link to={"/products/" + code}>
						<div className="app-item-div">
							<div>{this.renderProductImage(image, name)}</div>
							<Clearfix/>
							<h5>{code + " - " + name}</h5>
              {this.renderProductPrice(
                item.display_price,
                item.standard_rate,
                item.sub_category
              )}
						</div>
						</Link>
					</Col>
				);
			});
		}
		else {
			return (
				<p>No products available in this category yet.</p>
			);
		}
	}

  fetchSubCatgory() {
    let api = appAPI + 'method=subcategory';
    return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
    .then((resp) => resp.json())
    .then((resj) => {
      this.setState({
        subcategory:resj.data
      },function(){
        this.setState({appFlow:'default'})
      })
    })
    .catch((err) => {
      this.setState({
				appFlow:'error',
				appMethod:'::fetchSubCatgory'
			})
			console.log(err);
    })
  }

  renderSubCategory() {
    let subs = this.state.subcategory;
    return subs.map((item, index) => {
      let link = "/subitems/"+this.state.name+"/"+item.sub_category_name
      return (
        <li className='list-group-item' key={index}>
          <a href={link}>
            {item.sub_category_name}
          </a>
        </li>
      );
    })
  }

	//----------------------------------------------------------------------------

  render() {
		switch(this.state.appFlow) {
			case 'default':
				return (
					<div className="app-canvas">
          <Grid fluid>
            <Navigator/>
          </Grid>
		      <Grid>
		        <Row className="app-body-container">
							<Col xs={12} md={9}>
								<h2>
                  {this.state.name} - {this.state.sub}
                </h2><hr className="spliter"/>
								<br/>
                {this.renderProducts(this.state.name)}
							</Col>
              <Col xs={12} md={3}>
                <p><strong>Sub Categories</strong></p>
                <ul className="list-group">
                {this.renderSubCategory()}
                </ul>
              </Col>
						</Row>
						<Footer/>
		      </Grid>
					</div>
		    );

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								Unable to display content. Please check your internet connection status
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);
		}
  }
}
