import React, { Component } from 'react';
import {Grid, Row, Col, Clearfix, Table} from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import {appURL, appAPI} from '../component/appconst.js';
import {AppActivity} from '../component/apputils.js';
import Navigator from '../Navigator.js';
import Footer from '../Footer.js';
import '../App.css';

export default class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
			appFlow: 'loading',
			appMethod: '',
			appProgress: '',
      name: null,
			data: [],
      subcategory:[]
    }
  }

	componentDidMount() {
		let name = this.props.match.params.code;
		this.setState({
			name: name
		},function() {
			this.fetchProduct(this.state.name);
		})
	}

	fetchProduct(name) {
		let api = appAPI + 'method=item&name=' + name;
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			if(resj.success === true) {
				this.setState({
					data: resj.data[0],
				},function(){
					console.log('product fetched');
					console.log(this.state.data);
					this.setState({
						appFlow: 'default'
					})
				})
			}
			else {
				this.setState({
					appFlow:'error'
				})
			}
		})
		.catch((error) => {
			this.setState({
				appFlow:'error',
				appMethod:'::fetchProduct'
			})
			console.log(error);
		})
	}

	renderProductImage(image, name) {
		if(image === null) {
			return (
				<div className="app-img-container">
					<img src="../images/empty_100x100.png" alt={name} className="app-item"/>
				</div>
			);
		}
		else {
			// return image with api path
			return (
				<div className="app-img-container">
					<img src={appURL + image} alt={name} className="app-item"/>
				</div>
			);
		}
	}

	renderProductDescription() {
		let body = this.state.data.description;
		return (
			<div>
				<div dangerouslySetInnerHTML={this.htmlMarkup(body)}></div>
			</div>
		)
	}

	htmlMarkup(output) {
		if(output !== '' && output !== null && output !== undefined) {
			return {__html: output.replace(/\n/g, '<br />')}
		}
	}

  renderProductPrice(enable, price, subcategory) {
		if(enable === 1) {
			let rate = Number(price).toFixed(2)
			return (
				<div className="well well-sm app-well">
					RM {rate} | <small>{subcategory}</small>
				</div>
			);
		}
		else {
			return (
				<div className="well well-sm app-well">
					RM <span style={{color:'grey'}}>N/A</span> | <small>Sub Category: {subcategory}</small>
				</div>
			);
		}
	}

	//----------------------------------------------------------------------------

  render() {
		switch(this.state.appFlow) {

			case 'default':
				let data = this.state.data;
				let title = this.state.data.item_name
				if(data.custom_title !== null && data.custom_title !== undefined & data.custom_title !== '') {
					title = data.custom_title;
				}
				console.log(data.name);
				return (
					<div className="app-canvas">
					<Grid fluid>
						<Navigator/>
          </Grid>
          <Grid>
						<Row className="app-body-container">
							<Row className="app-body-title">
								<Col xs={12} md={12}>
									<h2>{title}</h2>
									<hr className="spliter"/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={4}>
									{this.renderProductImage(this.state.data.image, this.state.data.item_name)}
								</Col>
								<Col xs={12} md={8}>
                  <Col xs={12} md={12}>
									{this.renderProductDescription()}
                  {this.renderProductPrice(
                    this.state.data.display_price,
                    this.state.data.standard_rate,
                    this.state.data.sub_category
                  )}
                  </Col>
								</Col>
							</Row>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity
									text='Unable to display this page at the moment'
									icon=''
								/>
							</Col>
						</Row>
					</Grid>
					</div>
				);
		}
  }
}
