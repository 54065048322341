import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import '../App.css';

export class AppActivity extends Component {
	setActivityIcon(type) {
		switch(type) {
			case 'loading':
				return <i className="fa fa-spinner fa-spin"></i>;

			default: return null;
		}
	}

	render() {
		let text = 'Loading...';
		let icon = this.setActivityIcon('loading');

		if(this.props.text !== undefined) {
			text = this.props.text;
		}
		if(this.props.icon !== undefined) {
			icon = this.setActivityIcon(this.props.icon)
		}

		//console.log('prop text is')
		//console.log(this.props.text)

		return (
			<div className="loading-container">
				{text}
				<span>{icon}</span>
			</div>
		);
	}
}
