import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import {AppActivity} from './component/apputils.js';
import Navigator from './Navigator.js';
import Footer from './Footer.js';
import {Grid, Row, Col, Clearfix} from 'react-bootstrap';
import {appAPI, appURL} from './component/appconst.js';
//import {sampleProducts} from './component/products.js';
import './App.css';

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appFlow:'loading',
			appMethod:'',
			appProgress:'',
			welcome:'',
			products: [],
			slideshow: [],
			news:[],
		}
	}

	componentWillUnmount() {
			clearTimeout(this.timeoutHandle);
	}

	componentDidMount() {
		this.fetchProducts();
	}

	getWelcomeText() {
		let api = appAPI + 'method=page&name=welcome'
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			this.setState({
				welcome:resj.data[0].body[0]
			},function(){
				console.log('welcome text fetched...');
				console.log('all required data fetched')
				this.setState({
					appFlow:'default'
				})
			})
		})
		.catch((error) => {
			console.log('')
			this.setState({
				appFlow:'error',
				appMethod:'::getPage'
			})
		})
	}

	fetchProducts() {
		let api = appAPI + 'method=featitems';
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			if(resj.success === true) {
				this.setState({
					products: resj.data,
				},function(){
					console.log('featured products fetched...')
					this.fetchSlideshow()
				})
			}
			else {
				this.setState({
					appFlow:'default'
				})
			}
		})
		.catch((error) => {
			this.setState({
				appFlow:'error',
				appMethod:'::fetchProducts'
			})
			console.log(error);
		})
	}

	fetchSlideshow() {
		let api = appAPI + 'method=slideshow&name=Home';
		return fetch(api)
		.then((resp) => resp.json())
		.then((resj) => {
			if(resj.success === true) {
				this.setState({
					slideshow:resj.data
				},function(){
					console.log('slideshow fetched');
					//console.log(this.state.slideshow);
					this.fetchNewsHeadlines()
				})
			}
			else {
				this.setState({
					appFlow:'error',
					appMethod:'::fetchSlideshow'
				});
				console.log(resj)
			}
		})
		.catch((error) => {
			this.setState({
				appFlow:'error',
				appMethod:'::fetchSlideshow'
			});
			console.log(error);
		})
	}

	fetchNewsHeadlines() {
		let api = appAPI + 'method=newslist'
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			this.setState({
				news:resj.data
			},function() {
				console.log('news fetched...')
				this.getWelcomeText()
			})
		})
		.catch((err) => {
			console.log(err)
			this.setState({
				appFlow:'error',
				appMethod:'::getNews'
			})
		})
	}

	renderSlideshowImage() {
		const {slideshow} = this.state;
		return slideshow.map((item, index) => {
			return (
				<div key={index} className="app-slideshow">
					<div style={{
						'backgroundImage':`url(${item.uri})`
					}}>
					</div>
				</div>
			)
		})
	}

	renderSlideshow() {
		const slideshow = this.state.slideshow;
		if(slideshow.length > 0) {
			if(slideshow.length === 1) {
				return (
					<img src={slideshow.uri} alt={slideshow.heading}/>
				);
			}
			else {
				const settings = {
					duration: 5000,
					transitionDuration: 500,
		  		infinite: true,
		  		indicators: true,
		  		arrows: false
				}
				return (
					<Slide {...settings}>
						{this.renderSlideshowImage()}
					</Slide>
				);
			}
		}
	}

	renderProductImage(image, name) {
		if(image === null) {
			return (
				<div align="center">
					<img src="../images/empty_100x100.png" alt={name} className="app-item"/>
				</div>
			);
		}
		else {
			// return image with api path
			return (
				<div align="center">
					<img src={appURL + image} alt={name} className="app-item-thumb"/>
				</div>
			);
		}
	}

	renderProductPrice(enable, price, subcategory) {
		if(enable === 1) {
			let rate = Number(price).toFixed(2)
			return (
				<p>
					RM {rate}
				</p>
			);
		}
		else {
			return (
				<p>
					RM <span style={{color:'grey'}}>N/A</span>
				</p>
			);
		}
	}

	renderProducts(category) {
		const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
		const {products} = this.state;
		let indexs = [];

		if(products.length > 0) {
			let items = [];
			for(let i=0;i<products.length;i++) {
				if(products[i].main_category === category) {
					items.push(products[i])
				}
			}
			if(items.length > 0) {
				return items.map((item, index) => {
					let name 	= item.item_name;
					let code 	= item.name;
					let rate 	= item.standard_rate;
					let image = item.image;
					let title = name;

					if(item.custom_title !== null && item.custom_title !== undefined & item.custom_title !== '') {
						title = item.custom_title;
					}

					console.log('title is: ',title)

					return (
						<Col xs={6} md={4} className="app-item-col" key={index}>
							<Link to={"/products/" + code}>
								<div className="app-item-div">
									{this.renderProductImage(image, name)}
									<Clearfix/>
									<ResponsiveEllipsis
										style={{ whiteSpace: 'pre-wrap' }}
									  text={title}
									  maxLine='2'
									  ellipsis='...'
									  trimRight
									  basedOn='letters'
									/>

									{this.renderProductPrice(
										item.display_price,
										item.standard_rate,
										item.sub_category
									)}
							</div>
							</Link>
						</Col>
					);
				})
			}
			else {
				return (
					<Col xs={12} md={12}>
						<p style={{color:'grey'}}>
							No product to display at the moment.
						</p>
					</Col>
				);
			}
		}
		else {
			return (
				<Col xs={12} md={12}>
					<p style={{color:'grey'}}>
						No product to display at the moment.
					</p>
				</Col>
			);
		}
	}

	renderWelcomeText() {
		let title = this.state.welcome.title;
		let body = this.state.welcome.main_section;
		return (
			<div>
				<h2>{title}</h2>
				<div dangerouslySetInnerHTML={this.htmlMarkup(body)}></div>
			</div>
		)
	}

	renderNewsList() {
		const {news} = this.state;
		return news.map((item, index) => {
			return (
				<div key={index}>
					<h5><Link to={"/news/" + item.route}>{item.title}</Link></h5>
					<span><Link to={"/news/" + item.route} className="btn btn-sm btn-primary">Read More</Link></span>
					<hr className="spliter"/>
				</div>
			);
		})
	}

	htmlMarkup(output) {
		if(output !== '' && output !== null && output !== undefined) {
			return {__html: output.replace(/\n/g, '<br />')};
		}
	}

	//----------------------------------------------------------------------------

  render() {
		switch(this.state.appFlow) {
			case 'default':
				return (
					<div className="app-canvas">
					<Grid fluid>
						<Navigator/>
					</Grid>
					{this.renderSlideshow()}
					<Grid>
						<Row className="app-body-container">
							<Col xs={12} md={3}>
								<div className="welcome-div">
								{this.renderWelcomeText()}
								</div>
								<div className="app-logo-container">
									<img src="/images/logo.png" alt="Paragon Pets" className="app-logo"/>
								</div>
							</Col>

							<Col xs={12} md={6}>
								<Row>
									<Col xs={12} md={12} className="app-item-cat">Dogs</Col>
								</Row>
								<Row>
									{this.renderProducts('Dogs')}
								</Row>
								<hr className="spliter"/>
								<Row>
									<Col xs={12} md={12} className="app-item-cat">Cats</Col>
								</Row>
								<Row>
									{this.renderProducts('Cats')}
								</Row>
								<hr className="spliter"/>
								<Row>
									<Col xs={12} md={12} className="app-item-cat">Small Animals</Col>
								</Row>
								<Row>
									{this.renderProducts('Small Animals')}
								</Row>
								<hr className="spliter"/>
								<Row><br/><br/></Row>
							</Col>

							<Col xs={12} md={3}>
								<Row>
									<Col xs={12} md={12} className="app-item-cat">News & Promotions</Col>
								</Row>
								<Row>
									<Col xs={12} md={12} className="app-item-news">
										<div className="news-container">
										{this.renderNewsList()}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								Unable to display content. Please check your internet connection status
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);
		}
  }
}
