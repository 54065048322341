import React from "react";
import { Route, Switch } from "react-router-dom";

import App from './App.js';
import Page from './screen/page.js';
import News from './screen/news.js';
import Products from './screen/product.js';
import Category from './screen/category.js';
import SubCategory from './screen/subcategory.js';
import Member from './screen/member.js';

const AppRouter = () => (
  <React.Fragment>
		<Switch>
    <Route path="/" exact={true} component={ App } />
    <Route path="/page/:page" component={ Page } />
    <Route path="/news/:route" component={ News } />
		<Route path="/products/:code" component={ Products } />
		<Route path="/category/:name" component={ Category } />
    <Route path="/subitems/:name/:sub" component={ SubCategory } />
		<Route path="/member" component={ Member } />
		</Switch>
  </React.Fragment>
);

export default AppRouter;
