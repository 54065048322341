import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Grid, Row, Col, Clearfix} from 'react-bootstrap';
import { Slide, Fade } from 'react-slideshow-image';

import {AppActivity} from '../component/apputils.js';
import Navigator from '../Navigator.js';
import Footer from '../Footer.js';
import {appAPI} from '../component/appconst.js';
import '../App.css';

export default class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
			appFlow: 'loading',
			appMethod: '',
      body:[],
      slideshow:[],
    }
  }

	componentDidMount() {
		let name = this.props.match.params.route;
		this.fetchNewsItem(name)
	}

  fetchNewsHeadlines() {
		let api = appAPI + 'method=newslist'
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			this.setState({
				news:resj.data
			},function() {
				console.log('news fetched...')
				this.setState({
          appFlow:'default'
        })
			})
		})
		.catch((err) => {
			console.log(err)
			this.setState({
				appFlow:'error',
				appMethod:'::getNews'
			})
		})
	}

	fetchNewsItem(name) {
		let api = appAPI + 'method=newsitem&name=' + name;
		return fetch(api, {
			method:'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then((resp) => resp.json())
		.then((resj) => {
			this.setState({
				body:resj.data[0].body[0],
        slideshow:resj.data[0].slideshow
			},function() {
        console.log('news item fetched')
        //console.log('body: ')
        //console.log(this.state.body)
        //console.log('slideshows: ')
        //console.log(this.state.slideshow)
        this.fetchNewsHeadlines()
      })
		})
		.catch((error) => {
			console.log('')
			this.setState({
				appFlow:'error',
				appMethod:'::getNews'
			})
		})
	}

	renderSlideshowImage() {
		const {slideshow} = this.state;
		return slideshow.map((item, index) => {
			return (
				<div key={index} className="app-slideshow">
					<div style={{
						'backgroundImage':`url(${item.uri})`
					}}>
					</div>
				</div>
			)
		})
	}

	renderSlideshow() {
		let slideshow = this.state.slideshow;
		if(slideshow.length > 0) {
			if(slideshow.length === 1) {
				return (
					<div style={{marginBottom:20}}>
						<img
							src={slideshow[0].uri}
							alt={slideshow[0].heading}
							className="img-responsive"
						/>
					</div>
				);
			}
			else {
				const settings = {
					duration: 5000,
					transitionDuration: 500,
		  		infinite: true,
		  		indicators: true,
		  		arrows: false
				}
				return (
					<Slide {...settings}>
						{this.renderSlideshowImage()}
					</Slide>
				);
			}
		}
	}

  renderNewsList() {
		const {news} = this.state;
		return news.map((item, index) => {
      let uri = '/news/'+item.route
			return (
				<div key={index}>
					<h5><a href={uri}>{item.title}</a></h5>
					<span><a href={uri} className="btn btn-sm btn-primary">Read More</a></span>
					<hr className="spliter"/>
				</div>
			);
		})
	}

	htmlMarkup(output) {
		if(output !== undefined) {
			return {__html: output.replace(/\n/g, '<br />')};
		}
	}

	//----------------------------------------------------------------------------

  render() {
    let body = this.state.body;
    //console.log(body)
		switch(this.state.appFlow) {
			case 'default':
				return (
					<div className="app-canvas">
					<Grid fluid>
						<Navigator/>
          </Grid>
          <Grid>
						<Row className="app-body-container">
							<Col xs={12} md={9}>
                <h2>{body.title}</h2>
                <br/>
                {this.renderSlideshow()}
                <div dangerouslySetInnerHTML={this.htmlMarkup(body.main_section)}></div>
                <br/>
							</Col>
              <Col xs={12} md={3}>
                <Row>
                  <Col xs={12} md={12} className="app-item-news">
                    <p><strong>All News & Updates</strong></p>
                    <div className="news-container">
                    {this.renderNewsList()}
                    </div>
                  </Col>
                </Row>
              </Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								Unable to display content. Please check your internet connection status
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

		}
  }
}
/*
<h2>{this.state.title}</h2>
<br/>
{this.renderSlideshow()}
<div dangerouslySetInnerHTML={this.htmlMarkup(this.state.content)}></div>
<br/>
*/
