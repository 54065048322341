import React, { Component } from 'react';
import {Grid, Row, Col, Clearfix} from 'react-bootstrap';
import { Slide, Fade } from 'react-slideshow-image';

import {AppActivity} from '../component/apputils.js';
import Navigator from '../Navigator.js';
import Footer from '../Footer.js';
import {appAPI} from '../component/appconst.js';
import '../App.css';

export default class Page extends Component {
  constructor(props) {
    super(props)
    this.state = {
			appFlow: 'loading',
			appMethod: '',
      page: null,
			title: null,
			content: null,
			slideshow: []
    }
  }

	componentDidMount() {
		//let name = this.props.match.params.page;
		//console.log('name is: ' + name);
		//this.fetchSlideshow();
		//this.getPage(name);
		this.setState({
			appFlow:'default'
		})
	}

	//----------------------------------------------------------------------------

  render() {
		switch(this.state.appFlow) {
			case 'default':
				return (
					<div className="app-canvas">
					<Grid fluid>
						<Navigator/>
          </Grid>
          <Grid>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<h2>Apps Dedicated For Our Valuable Customers</h2>
								<br/>
								<div align="center">
									<img style={{borderRadius:5}} src="./images/member.jpg" alt="Paragon Pets Mobile App" className="img-responsive"/>
									<h3>Download our apps today, dedicated our valuable customers.</h3>
									See your transaction histories, current reward points and more.<br/><br/>
									<a href="https://play.google.com/store/apps/details?id=com.paragonpets">
									<img src="./images/badge-android.png" alt="Android - Paragon Pets Mobile App"/>
									</a>
                  <a href="https://itunes.apple.com/my/app/paragon-pets/id1443577787?mt=8">
									<img src="./images/badge-ios.png" alt="iOS - Paragon Pets Mobile App"/>
                  </a>
								</div>
								<br/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			case 'loading':
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								<AppActivity/>
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

			default:
				return (
					<div className="app-canvas">
					<Grid>
						<Navigator/>
						<Row className="app-body-container">
							<Col xs={12} md={12}>
								Unable to display content. Please check your internet connection status
							</Col>
						</Row>
						<Footer/>
					</Grid>
					</div>
				);

		}
  }
}
